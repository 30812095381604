<template>
    <div class="editorAttr">
        <div class="header flex">
            <div class="flex-1">选项</div>
            <div style="width:100px;text-align:center;">操作</div>
        </div>
        <div class="content">
            <div v-if="list && list.length>0" class="list">
                <div v-for="(item,idx) in list" :key="idx" class="flex">
                    <div class="flex-1">
                        <el-input style="width:90px;margin-right:5px;" maxlength="8" size="mini" v-model="item.v" placeholder="请输入内容"></el-input>
                    </div>
                    <div style="width:100px;text-align:center;">
                        <el-button @click="delClass(idx)" type="text" size="mini">删除</el-button>
                    </div>
                </div>
            </div>
            <div class="add flex" style="padding:10px 0;">
                <div><el-input style="width:120px;margin-right:5px;" size="mini" v-model="createdName" placeholder="请输入内容"></el-input></div>
                <div><el-button size="mini" @click="addClass()" type="primary">确认</el-button></div>
            </div>
        </div>
        <div style="padding-top:20px;">
            <el-button type="primary" size="small" @click="submit()" style="width: 80px;">提交</el-button>
        </div>
    </div>
</template>
<script>
    import { aptitudeList,editAptitude } from '@/service/dictionary';
    
    export default {
        components:{},
        props: ['item','popTitle'],
        data() {
            return {
                list:"",
                createdName:"",
                colorValue:"",
            }
        },
        watch:{
            popTitle:{
                handler() {
                    this.list = this.item.options;
                },
                deep: true
            }
        },
        created() {
            this.list = this.item.options;
        },
        mounted() {},
        methods: {
            addClass() {
                if (this.createdName) {
                    this.list.push({
                        k:(new Date()).getTime(),
                        v:this.createdName,
                    });
                    this.createdName = "";
                }
            },
            delClass(index) {
                this.list.splice(index,1);
            },
            submit() {
                let obj = {
                    id:this.item.id,
                    options:this.list,
                }
                editAptitude(obj).then(rst => {
                    this.$emit("hide");
                    this.$message.success("添加成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    .header>div {
        font-weight: 600;
        color: #000;
        line-height:30px;
    }
    .content {
        max-height: 300px;
        overflow-y: auto;
    }
    .list>div {
        margin: 10px 0;
    }
</style>