<template>
    <div style="height:100%">
        <div class="page-header">资质字典</div>
        <div class="block" style="height: calc(100% - 159px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="id" label="ID" width="120">
                    </el-table-column>
                    <el-table-column prop="name" label="字段名成">
                    </el-table-column>
                    <el-table-column prop="form_name" label="分类名称">
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row,scope.$index)">
                                <span class="icon iconfont icon-bianji1"></span>&nbsp;编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditAptitude :item="item" :popTitle="popTitle" @hide="hide"></EditAptitude>
        </el-dialog>
    </div>
</template>
<script>
    import { aptitudeList } from '@/service/dictionary';
    import EditAptitude from './EditAptitude.vue';
    export default {
        components: {
            EditAptitude,
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                list:[],
                item:"",
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                aptitudeList().then(rst => {
                    if (rst && rst.length>0) {
                        this.list = rst;
                    }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            edit(item,index) {
                this.item = item;
                this.popTitle = item.name;
                this.popVisible = true;
            },
            hide() {
                this.popVisible=false;
                this.refreshItems();
            }
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped>
    .icon {
        font-size: 13px;
    }
</style>